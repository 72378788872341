import React from 'react'
import gestionAgenceService from '../../services/gestionAgenceService';
class AgenciesBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null 
        };
    }

    componentDidMount() {
        gestionAgenceService.getGestionAgence().then(response => {
            const data = response.data;
            this.setState({
                data
            });
        })
    }

    render() {
        const data = this.state.data ?? {content: ""};

        return (
          <>
                <div className="container">
                    <section className="agences">
                        <div className="agencesFlex">
                            <div className="agencesFlexList">
                                <div dangerouslySetInnerHTML={{ __html: data.content }} />
                            </div>
                            <div className="agencesFlexMap">
                                <iframe src="https://www.google.com/maps/d/embed?mid=1cefSSF-5oE9iKz9T_ftMoOGcZUUKgKFS" width="640" height="480"></iframe>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}
export default AgenciesBlock